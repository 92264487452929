import React, { lazy } from 'react';
import {
	primaryChemistMenu,
	layoutPages,
	chemistMenuGroupOverview,
	chemistMenuGroupManagement,
	chemistMenuGroupLogistics,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import PrivateRoute from '../PrivateRoute/PrivateRoute';
import LiveData from "../pages/full/LiveData";

const CHEMIST_CONTENT_ROUTES = {
	DASHBOARD: {
		DASHBOARD: lazy(() => import('../pages/chemist-pages/dashboard/DashboardView')),
	},
	ANNOUNCEMENT: {
		ANNOUNCEMENT_LIST: lazy(() =>
			import('../pages/chemist-pages/announcement/AnnouncementList'),
		),
	},
	LEADS: {
		LEADS_LIST: lazy(() => import('../pages/chemist-pages/leads/LeadsList')),
	},
	STOCK: {
		STOCK_LIST: lazy(() => import('../pages/chemist-pages/inventory/Stock')),
	},
	TRANSFERS: {
		TRANSFERS_LIST: lazy(() => import('../pages/chemist-pages/inventory/Transfers')),
	},
	QUANTITIES: {
		QUANTITIES_LIST: lazy(() => import('../pages/chemist-pages/inventory/Quantities')),
	},
	REQUISITIONS: {
		REQUISITIONS_LIST: lazy(() => import('../pages/chemist-pages/requisitions/Requisitions')),
	},
	TARGETANDACHIEVEMENT: {
		TARGETANDACHIEVEMENT_LIST: lazy(() =>
			import('../pages/chemist-pages/data/TargetAndAchievement'),
		),
	},
	DEPOTREVENUECOLLECTION: {
		DEPOTREVENUECOLLECTION_LIST: lazy(() =>
			import('../pages/chemist-pages/data/DepotRevenueCollection'),
		),
	},
	SALESREPORT: {
		SALESREPORT_LIST: lazy(() => import('../pages/chemist-pages/data/SalesReport')),
	},
	PRESCRIPTIONREPORT: {
		PRESCRIPTIONREPORT_LIST: lazy(() =>
			import('../pages/chemist-pages/data/PrescriptionReport'),
		),
	},
	DOCTORVISIT: {
		DOCTORVISIT_LIST: lazy(() => import('../pages/chemist-pages/data/DoctorVisit')),
	},
	ENTITY: {
		ENTITY_LIST: lazy(() => import('../pages/chemist-pages/data/Entity')),
	},
	REPORTINGDATA: {
		REPORTDATALIST: lazy(() => import('../pages/chemist-pages/data/ReportingData')),
	},
	MRDREPORT: {
		MRDREPORTLIST: lazy(() => import('../pages/chemist-pages/reporting/MRDReport')),
	},
	DELIVERIES: {
		DELIVERIES_LIST: lazy(() => import('../pages/chemist-pages/deliveries/Deliveries')),
	},
	PRODUCTWISEPRESCRIPTION:{
		PRESCRIPTION_LIST: lazy(()=> import('../pages/chemist-pages/reporting/ProductWisePrescription'))
	},
	PRODUCTDEPOTWISEPRESCRIPTION:{
		PRESCRIPTION_LIST:lazy(()=>import('../pages/chemist-pages/reporting/ProductDepotWisePrescriptionReport'))
	},
	PRESCRIPTIONTARGET:{
		PRESCRIPTION_TARGET_LIST:lazy(()=>import('../pages/chemist-pages/reporting/PrescriptionTarget'))
	},
	VISITTARGET:{
		VISIT_TARGET_LIST:lazy(()=>import('../pages/chemist-pages/reporting/DocVisitTarget'))
	},
	REQUISTIONTARGET:{
		REQUISITION_TARGET_LIST:lazy(()=>import('../pages/chemist-pages/reporting/RequisitionTarget'))
	},
	OVERALLVISITREPORT:{
		OVERALLVISITREPORT_LIST:lazy(()=>import('../pages/chemist-pages/reporting/OverallVisitReport'))
	},
	TOPVISIT:{
		TOP_VISIT_LIST:lazy(()=>import('../pages/chemist-pages/reporting/TopEmployeeByDocVisit'))
	},
	TOPPRESCRIPTION:{
		TOP_PRESCRIPTION_LIST:lazy(()=>import('../pages/chemist-pages/reporting/TopEmployeeByPres'))
	},
	TOPPRODUCTS:{
		TOP_PRODUCT_LIST:lazy(()=>import('../pages/chemist-pages/reporting/TopFiveProducts'))
	},
	MARKETCOVERAGE: {
		MARKET_COVERAGE: lazy(() =>
			import('../pages/chemist-pages/market-coverage/MarketCoverageView'),
		),
	},
	ATTENDENCE:{
		ATTENDENCE_REPORT:lazy(()=> import('../pages/chemist-pages/reporting/AttendenceReport'))
	},
	TARGETS: {
		TARGETSVIEW: lazy(() => import('../pages/chemist-pages/targets/TargetView')),
		DEPOTWISETARGET: lazy(() => import('../pages/chemist-pages/targets/DepotWiseTarget')),
	},
	SPECIAL_PROGRAM: {
		SPECIAL_PROGRAM_LIST: lazy(() => import('../pages/chemist-pages/SpecialProgram/SpecialProgram')),
	},
	SETTINGS:{
		TERRITORY:lazy(()=> import('../pages/chemist-pages/settings/Territory')),
		PHARMACY_LIST: lazy(() => import('../pages/chemist-pages/settings/PharmacyList')),
		MANAGE_PRODUCT: lazy(() => import('../pages/chemist-pages/settings/ManageProduct')),
		WAREHOUSE_LIST: lazy(() => import('../pages/chemist-pages/settings/WareHouseList')),
		USERS: lazy(() => import('../pages/chemist-pages/settings/UserManagement')),
		PRODUCTS_LIST: lazy(() => import('../pages/chemist-pages/settings/ProductsList')),
		DOCTORS_LIST: lazy(() => import('../pages/chemist-pages/settings/DoctorsManagement')),
	}
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


const presentation = [
	/*
	* OVERVIEW
	*/
	{
		path: chemistMenuGroupOverview.dashboard.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.DASHBOARD.DASHBOARD />{' '}
			</PrivateRoute>
		),
		exact: true,
	},
	// {
	// 	path: chemistMenuGroupOverview.coverage.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.MARKETCOVERAGE.MARKET_COVERAGE />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	/*
	 * ANNOUNCEMENT
	 */
	// {
	// 	path: chemistMenuGroupManagement.announcements.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.ANNOUNCEMENT.ANNOUNCEMENT_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	// {
	// 	path: chemistMenuGroupManagement.leads.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.LEADS.LEADS_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	// {
	// 	path: chemistMenuGroupLogistics.inventory.subMenu.stock.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.STOCK.STOCK_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	// {
	// 	path: chemistMenuGroupLogistics.inventory.subMenu.transfers.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.TRANSFERS.TRANSFERS_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	// {
	// 	path: chemistMenuGroupLogistics.inventory.subMenu.minimum_quantities.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.QUANTITIES.QUANTITIES_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	{
		path: chemistMenuGroupLogistics.requisitions.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.REQUISITIONS.REQUISITIONS_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.target_and_achievement.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.TARGETANDACHIEVEMENT.TARGETANDACHIEVEMENT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.product_wise_prescription.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.PRODUCTWISEPRESCRIPTION.PRESCRIPTION_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.product_and_depot_wise_prescription_report.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.PRODUCTDEPOTWISEPRESCRIPTION.PRESCRIPTION_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.prescription_target.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.PRESCRIPTIONTARGET.PRESCRIPTION_TARGET_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.visit_target.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.VISITTARGET.VISIT_TARGET_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.requisition_target.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.REQUISTIONTARGET.REQUISITION_TARGET_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.top_visit.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.TOPVISIT.TOP_VISIT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.top_prescription.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.TOPPRESCRIPTION.TOP_PRESCRIPTION_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.top_products.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.TOPPRODUCTS.TOP_PRODUCT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.overall_visit_report.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.OVERALLVISITREPORT.OVERALLVISITREPORT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.attendence_report.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.ATTENDENCE.ATTENDENCE_REPORT />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.overall_mrd_report.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.MRDREPORT.MRDREPORTLIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.depot_Revenue_collection.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.DEPOTREVENUECOLLECTION.DEPOTREVENUECOLLECTION_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.prescriptions.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.PRESCRIPTIONREPORT.PRESCRIPTIONREPORT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.doctor_visit.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.DOCTORVISIT.DOCTORVISIT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.entity.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.ENTITY.ENTITY_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.reportingData.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.REPORTINGDATA.REPORTDATALIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.sales.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SALESREPORT.SALESREPORT_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	// {
	// 	path: chemistMenuGroupLogistics.deliveries.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.DELIVERIES.DELIVERIES_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },

	/*
	 * SETTINGS
	 */
	{
		path: primaryChemistMenu.settings.subMenu.territory.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.TERRITORY />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.pharmacies.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.PHARMACY_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.manageProducts.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.MANAGE_PRODUCT />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.warehouses.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.WAREHOUSE_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.users.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.USERS />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		index: true,
		path: primaryChemistMenu.settings.subMenu.product.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.PRODUCTS_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		index: true,
		path: primaryChemistMenu.settings.subMenu.doctors.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.SETTINGS.DOCTORS_LIST />
			</PrivateRoute>
		),
		exact: true,
	},
	/*
	 * TARGETS
	 */
	{
		path: chemistMenuGroupManagement.targets.subMenu.target.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.TARGETS.TARGETSVIEW />
			</PrivateRoute>
		),
		exact: true,
	},
	{
		path: chemistMenuGroupManagement.targets.subMenu.depot_wise_target.path,
		element: (
			<PrivateRoute>
				<CHEMIST_CONTENT_ROUTES.TARGETS.DEPOTWISETARGET />
			</PrivateRoute>
		),
		exact: true,
	},
	// {
	// 	path: chemistMenuGroupManagement.specialProgram.path,
	// 	element: (
	// 		<PrivateRoute>
	// 			<CHEMIST_CONTENT_ROUTES.SPECIAL_PROGRAM.SPECIAL_PROGRAM_LIST />
	// 		</PrivateRoute>
	// 	),
	// 	exact: true,
	// },
	{
		path: layoutPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: layoutPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: layoutPages.live_tv.path,
		element: <PrivateRoute><LiveData /></PrivateRoute>,
		exact: true,
	},
	{
		path: layoutPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];

const contents = [...presentation];

export default contents;
