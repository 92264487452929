import React from 'react';
import {
	chemistMenuGroupManagement,
	primaryChemistMenu,
	chemistMenuGroupLogistics,
	chemistMenuGroupOverview,
	layoutPages,
} from '../menu';
import ChemistHeader from '../pages/common/Headers/ChemistHeader';

const headers = [
	{
		path: chemistMenuGroupOverview.data.subMenu.target_and_achievement.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.depot_Revenue_collection.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.sales.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.data.subMenu.prescriptions.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.reporting.subMenu.product_wise_prescription.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.product.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.warehouses.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.pharmacies.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.users.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: primaryChemistMenu.settings.subMenu.doctors.path,
		element: <ChemistHeader />,
		exact: true,
	},
	// {
	// 	path: chemistMenuGroupLogistics.inventory.subMenu.stock.path,
	// 	element: <ChemistHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: chemistMenuGroupLogistics.inventory.subMenu.transfers.path,
	// 	element: <ChemistHeader />,
	// 	exact: true,
	// },
	// {
	// 	path: chemistMenuGroupLogistics.inventory.subMenu.minimum_quantities.path,
	// 	element: <ChemistHeader />,
	// 	exact: true,
	// },
	{
		path: chemistMenuGroupLogistics.requisitions.path,
		element: <ChemistHeader />,
		exact: true,
	},
	// {
	// 	path: chemistMenuGroupLogistics.deliveries.path,
	// 	element: <ChemistHeader />,
	// 	exact: true,
	// },
	{
		path: chemistMenuGroupManagement.targets.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: layoutPages.live_tv.path,
		exact: true,
	},
	{
		path: layoutPages.login.path,
		exact: true,
	},
	{
		path: chemistMenuGroupOverview.dashboard.path,
		element: <ChemistHeader />,
		exact: true,
	},
	{
		path: `*`,
		element: <ChemistHeader />,
	},
];

export default headers;
