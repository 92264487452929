import React from 'react';
import Footer from '../layout/Footer/Footer';
import { layoutPages } from '../menu';

const footers = [
    {
        path: '*',
        element: <Footer />
    },
    {
        path: layoutPages.live_tv.path,
        exact: true,
    },
    {
        path: layoutPages.live_tv.path,
        exact: true,
    },
    {
        path: layoutPages.login.path,
        exact: true,
    },
];

export default footers;
